<template>
    <div class="container prefix-live-center" v-if="is_data_ready">
        <div class="all_matches_content">
          <section class="sabc_league_list_section" v-if="!this.$route.name.includes('match_detail')">
            <div class="container">
              <div class="row" v-if="is_tab_ready">
                <div class="swiper mySwiper2">
                    <div class="swiper-wrapper">
                        <div :class=" ($route.params.leagueId == table.id) ? 'swiper_slide_selected' : '' " class="swiper-slide" v-for="table in tables">
                            <div class="sabc_league_list_box">
                              <div class="sabc_league_list_inr">
                                  <router-link :to="{name: 'rugby_league_detail', params: {league: $slugify(table.name), leagueId: table.id, matchType:leagueMatchType }}">
                                      <img :src="$findImage('rugby/leagues/'+table.rugby_league_id+'.webp','sabc_league_list_logo.png')" alt="league"/>
                                      <h2>
                                          {{table.name}}
                                      <p>
                                          {{table.footballCategory.name}}
                                      </p>
                                      </h2>
                                  </router-link>
                              </div>
                          </div>
                        </div>
                    </div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                    <!-- <div class="swiper-button-prev"></div> -->
                </div>
              </div>
            </div>
          </section>
            <div class="sabc_lc_tabs_date"
                 v-if="($route.name == 'rugby_listing' || $route.name == 'rugby.myMatches' || $route.name == 'rugby.tables'|| $route.name == 'rugby_league_detail')">
                <ListingTabs v-if="$route.name != 'rugby_league_detail'" :key="$route.params.date"></ListingTabs>
                <div v-else class="cricket_matches_tabs">
                    <ul>
                        <li>
                            <a :href="scoresLink"><span> &lt; All scores</span></a>
                        </li>
                        <li :class="($route.params.matchType == 'fixtures') ? 'selected' : ''">
                            <router-link :to="{name: 'rugby_league_detail', params: {league: $route.params.league, leagueId: $route.params.leagueId, matchType:'fixtures' }}" style="cursor: pointer">
                                <span>
                                    Fixtures
                                </span>
                            </router-link>
                        </li>
                        <li :class="($route.params.matchType == 'results') ? 'selected' : ''">
                            <router-link :to="{name: 'rugby_league_detail', params: {league: $route.params.league, leagueId: $route.params.leagueId, matchType:'results' }}" style="cursor: pointer">
                                <span>
                                    Results
                                </span>
                            </router-link>
                        </li>
                    </ul>
                    </div>
                <DateSlider :key="$route.params.date+'_slider'" v-if="$route.name != 'rugby.tables'"></DateSlider>
                <div class="sabc_year_drop" v-else>
                    <span>Seasons:</span>
                    <select @change="onChange($event)" v-model="selectedSeason">
                        <option>
                            All
                        </option>
                        <option v-for="season in seasons">
                            {{season}}
                        </option>
                    </select>
                </div>
            </div>
            <router-view />
        </div>
    </div>
</template>
<script>
    import DateSlider from '../Livecenter/Common/date-slider';
    import ListingTabs from '../Livecenter/Common/match-listing-tabs';
    import { Swiper, Navigation } from "swiper";
    Swiper.use([ Navigation ]);

    export default {
        name: "rugby-lc-home",
        data(){
            return {
                tables: [],
                seasons: [],
                selectedSeason: 'All',
                leagueMatchType: 'fixtures',
                scoresLink : '/live/rugby/matches/all/'+new Date().toISOString().slice(0, 10),
            }
        },
        components: {
            DateSlider, ListingTabs
        },
        async mounted() {
            await this.getAllTables();
            await this.runSlider();
        },
        serverPrefetch() {
            this.getAllTables();
        },
        watch: {
            $route(to, from) {
                if(to.name == 'rugby.tables' && from.name != 'rugby.tables' ) {
                    this.selectedSeason = 'All';
                }
                if((to.name == 'rugby_listing' || to.name == 'rugby_league_detail')  && from.name == 'rugby_match_detail.overview'){
                  //run slider to hide the previous and next icons when coming back to listing page from match overview page
                  this.runSlider();
                }
            }
        },
        methods:{
            getAllTables() {
                this.is_data_ready = this.is_tab_ready = false
                return this.$axios.get(this.$RUGBY_ALL_TABLES)
                    .then(response => {
                        if (response.data) {
                            this.tables = response.data.tables;
                            this.seasons = response.data.seasons;
                            this.is_data_ready = true;
                            this.is_tab_ready = true;
                        }
                    })
                    .catch(error => {
                    })
            },
            onChange(event) {
                this.$router.push({ name: 'rugby.tables', params: { season: this.selectedSeason } })
            },
            runSlider(){
                new Swiper('.mySwiper2', {
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    slidesPerView: 4,
                    observer: true,
                    observeParents: true,
                    paginationClickable: true,
                    spaceBetween: 16,
                    autoplay: false,

                    breakpoints: {
                        1199: {
                            slidesPerView: 4,
                            spaceBetween: 16
                        },
                        767: {
                            slidesPerView: 3,
                            spaceBetween: 16
                        },
                        200: {
                            slidesPerView: 2,
                            spaceBetween: 16
                        }
                    }
                });
            }
        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/rugby.scss';
@import '~@/assets/scss/football.scss';
</style>
